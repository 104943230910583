<template>
    <div>
        <h1>Kontakt</h1>

        <Form />

        <div class="row mt-5">
            <div class="col-md-6 mt-5">
                <img
                    src="../assets/images/contact-persons/lucia.jpg"
                    class="d-block w-50 mx-auto mb-4 rounded-circle shadow img-thumbnail"
                    alt="Lucia Rysulová - Kontateľ"
                />
                <h5><strong>Lucia Rysulová</strong> - Konateľ</h5>
                <p>
                    <i class="fas fa-phone-alt"></i>+421 902 135 475<br />
                    <a href="mailto:drevovyroba.rysula@gmail.com">drevovyroba.rysula@gmail.com</a>
                </p>
            </div>
            <div class="col-md-6 mt-5">
                <img
                    src="../assets/images/contact-persons/lubomir.jpg"
                    class="d-block w-50 mx-auto mb-4 rounded-circle shadow img-thumbnail"
                    alt="Ľubomír Rysula - Riaditeľ"
                />
                <h5><strong>Ľubomír Rysula</strong> - Riaditeľ</h5>
                <p>
                    <i class="fas fa-phone-alt"></i>+421 907 504 477<br />
                    <a href="mailto:drevovyroba.rysula@gmail.com">drevovyroba.rysula@gmail.com</a>
                </p>
            </div>
            <!-- <div class="col-md-4 mt-5">
                <img
                    src="../assets/images/contact-persons/ondrej.jpg"
                    class="d-block w-50 mx-auto mb-4 rounded-circle shadow img-thumbnail"
                    alt="Ondrej Jantačka - Obchodný manažér"
                />
                <h5><strong>Ondrej Jantačka</strong> - Obchodný manažér</h5>
                <p>
                    <i class="fas fa-phone-alt"></i>+421 944 926 797<br />
                    <a href="mailto:drevovyroba.rysula@gmail.com">drevovyroba.rysula@gmail.com</a>
                </p>
            </div> -->
        </div>

        <div class="row mt-5 text-start">
            <div class="col-md-8 mt-5">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5226.366197559614!2d19.5939143!3d49.0831608!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4715992bce660a3b%3A0x33101a18deb34871!2zU2xvdmVuc2vDqWhvIG7DoXJvZG7DqWhvIHBvdnN0YW5pZSAxMSwgMDMxIDAxIExpcHRvdnNrw70gTWlrdWzDocWhLVBhbMO6ZHprYQ!5e0!3m2!1ssk!2ssk!4v1630874136287!5m2!1ssk!2ssk"
                    width="100%"
                    height="370"
                    style="border:0;"
                    allowfullscreen=""
                    loading="lazy"
                ></iframe>
            </div>
            <div class="col-md-4 mt-5">
                <h4 class="mt-4">Adresa prevádzky:</h4>
                <h5>
                    <strong>SNP 10/106, Liptovský Mikuláš - Palúdzka</strong><br />
                    (Areál bývaleho Ligarexu)
                </h5>

                <h4 class="mt-4">Fakturačné údaje:</h4>
                <h5><strong>R-Different, s.r.o.</strong></h5>
                <p>IČO: 46919325<br />DIČ: 2023645063<br />IČ DPH: SK2023645063</p>
                <p>Bobrovec 282, 032 21 Bobrovec<br />Slovenská republika</p>
            </div>
        </div>
    </div>
</template>

<script>
import { Utils } from "@/mixins/Utils";
import Form from "@/components/contact-form/Form.vue";

export default {
    name: "Contact",
    mixins: [Utils],

    components: {
        Form,
    },

    data() {
        return {
            //
        };
    },

    mounted() {
        //
    },

    methods: {
        //
    },
};
</script>

<style lang="scss" scoped>
.fa-phone-alt {
    margin-right: 10px;
}
</style>
