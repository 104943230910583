<template>
    <div id="app">
        <Navbar :open="navbarKey" :phone="contact.phone" :email="contact.email" />

        <Header v-on:open-navbar="navbarKey++" />

        <main id="routerView">
            <!--<Breadcrumb />-->

            <section class="content">
                <div class="container text-center">
                    <router-view />
                </div>
            </section>

            <SectionVideo />

            <SectionServices :phone="contact.phone" />

            <SectionAboutUs />

            <SectionContact />
        </main>

        <Footer :phone="contact.phone" :email="contact.email" />
    </div>
</template>

<script>
import { Utils } from "@/mixins/Utils";

import Navbar from "./components/partials/Navbar.vue";
import Header from "./components/partials/Header.vue";
//import Breadcrumb from "./components/partials/Breadcrumb.vue";
import SectionVideo from "./components/partials/SectionVideo.vue";
import SectionServices from "./components/partials/SectionServices.vue";
import SectionAboutUs from "./components/partials/SectionAboutUs.vue";
import SectionContact from "./components/partials/SectionContact.vue";
import Footer from "./components/partials/Footer.vue";

export default {
    name: "App",
    mixins: [Utils],

    components: {
        Navbar,
        Header,
        //Breadcrumb,
        SectionVideo,
        SectionServices,
        SectionAboutUs,
        SectionContact,
        Footer,
    },

    data() {
        return {
            contact: {
                phone: "+421 944 926 797",
                email: "drevovyroba.rysula@gmail.com",
            },

            openNavbar: null,
            navbarKey: 0,
        };
    },

    mounted() {
        //
    },

    methods: {
        //
    },
};
</script>

<style lang="scss">
@import "./assets/scss/style.scss";

section.content {
    padding: 5rem 0 7rem;
}

img {
    max-width: 100%;
}

a {
    color: inherit;

    &:active,
    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
}

.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active,
.btn-outline-light:hover,
.btn-outline-light:focus {
    background: rgba(255, 255, 255, 0.3);
    border-color: transparent;
    color: #fff;
}
.btn,
input,
textarea {
    border-radius: 0 !important;
}

h1 {
    margin-bottom: 2rem;
}

h1,
h2 {
    font-family: "Big Shoulders Inline Text", cursive;
    zoom: 120%;
    text-transform: uppercase;
    color: #e7d696;
}
</style>
